<template>
  <div class="container">
    <div class="demo1">hkshkshdskds</div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: auto;
  .demo1 {
    color: #666;
    font-size: 2vw;
    text-align: center;
  }
  #back-ground-image {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .header {
    width: 100%;
    height: 10vh;
    letter-spacing: 4vw;
    padding-left: 1vw;
    background: linear-gradient(to top, #c0d9ff, #fff);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 2vw;
    text-align: center;
  }
}
</style>
